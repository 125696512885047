<template>
  <div class="profile-data-wrapper financial-graphs" v-show="!loading">
    <template
      v-if="
        userPermissions &&
        userPermissions.financial_graphs &&
        userPermissions.financial_graphs.length > 0 &&
        userPermissions.financial_graphs.includes('View')
      "
    >
   
      <div class="list-group">
        <template
            v-if="
              userPermissions &&
              userPermissions.financial_graphs &&
              userPermissions.financial_graphs.length > 0 &&
              userPermissions.financial_graphs.includes('Add') || 
              userPermissions.financial_graphs.includes('Update')
            "
          >
         <div class="profile-options">
        <div class="row align-items-center justify-content-between">
          <div class="col">
            <!-- <h2 class="section-heading">RECENT VERIFICATIONS</h2> -->
          </div>
          <div class="col">
             <a
              class="btn primary-btn"
              href="javascript:void(0);"
              @click.prevent="openSideModal('importFinancialReport')"
              >Import Data</a
            >
          </div>
          <!-- End div center   -->
        </div>
      </div>
      </template>   
        <template v-for="(item, index) in ListGroup" :key="index">
          <router-link
            :to="{ name: item.link }"
            :name="item.link"
            class="list-group-item flex-column align-items-center"
          >
            <div class="d-flex w-100 justify-content-between">
              <span class="list-group-item-text">{{ item.text }}</span>
              <div class="list-group-item-action">
                <ul class="d-flex justify-content-between">
                  <li>
                    <a class="custom-tooltip" href="javascript:void(0);" v-tippy="`Download CSV`" @click.prevent="downloadCsv(item.text)" :data-title="item.text">
                      <font-awesome-icon :icon="['fa', 'download']" />
                    </a>
                  </li>
                  <li>
                    <a class="custom-tooltip" href="javascript:void(0);" v-tippy="`View Details`">
                      <font-awesome-icon :icon="['fa', 'chevron-right']" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </router-link>
        </template>
      </div>
      <SideModal
        @close="closeSideModal"
        v-bind:contentClass="[
          'sm-side--modal',
          { 'finanical-modal': sideModals.import.show },
        ]"
      >
        <template v-slot:header>
          <template v-if="sideModals.import.show"
            >Import Data</template
          >
        </template>
        <template v-slot:body>
          <div
            class="text-center"
            v-if="sideModals.import.show"
          >
            <ImportFinancialReport v-if="sideModals.import.show"
            @close="closeSideModal"
            />
          </div>
        </template>
    </SideModal>
    </template>
    <template
      v-if="
        userPermissions &&
        userPermissions.financial_graphs &&
        (userPermissions.financial_graphs.length === 0 ||
          (userPermissions.financial_graphs.length > 0 &&
            !userPermissions.financial_graphs.includes('View')))
      "
      ><AccessDenied/></template
    >
  </div>
</template>
<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faChevronRight, faDownload } from "@fortawesome/free-solid-svg-icons";
import SideModal from "../components/SideModal.vue";
import ImportFinancialReport from "../components/ImportFinancialReport.vue";
import common from "../mixins/common";
import financialEarningService from "../services/financialEarning.service.js";
import financialGraphService from "../services/financialGraph.service";
import financialWHTService from "../services/financialWHT.service";
import financialMembershipFeeService from "../services/financialMembershipFee.service";
import financialRefoundService from "../services/financialRefound.service";
import financialBonusService from "../services/financialBonus.service";
import financialConnectsService from "../services/financialConnects.service";
import FinancialCashFlowService from "../services/financialCashFlow.service";
import AccessDenied from "../components/AccessDenied.vue";

library.add(faChevronRight, faDownload);
export default {
  data() {
    return {
      loading: true,
      ListGroup: [
        {
          text: "Earnings",
          link: "FinancialGraphsEarnings",
        },
        {
          text: "Cash Flow",
          link: "FinancialGraphsCashFlow",
        },
        {
          text: "Service Fee",
          link: "FinancialGraphsServiceFee",
        },
        {
          text: "Withholding Tax",
          link: "FinancialGraphsWithholdingTax",
        },
        {
          text: "Membership Fee",
          link: "FinancialGraphsMembershipFee",
        },
        {
          text: "Refunds",
          link: "FinancialGraphsRefunds",
        },
        {
          text: "Bonus",
          link: "FinancialGraphsBonus",
        },
        {
          text: "Connects",
          link: "FinancialGraphsConnects",
        },
        // {
        //   text: "Detailed Report",
        //   link: "FinancialGraphsDetailedReport",
        // },
      ],
      sideModals: {
        import: { show: false }
      },
    };
  },
  components: {
    FontAwesomeIcon,
    SideModal,
    ImportFinancialReport,
    AccessDenied
  },
  mixins: [common],
  methods: {
    async openSideModal(action) {
      let vm = this;
      await vm.$store.dispatch("getUserDetails");
      console.log('Permissions',vm.userPermissions.financial_graphs);
      if (
        vm.userPermissions &&
        vm.userPermissions.settings &&
        vm.userPermissions.settings.length > 0 &&
        vm.userPermissions.settings.includes("Add")
      ) {
        if (action === "importFinancialReport" && (vm.userPermissions.financial_graphs.includes("Add") || vm.userPermissions.financial_graphs.includes("Update"))) {
          vm.modalStatus = true;
          vm.sideModals.import.show = true;
          setTimeout(() => {
            document.body.classList.add("lmodal-open");
          }, 300);
        }
      }
    },
    closeSideModal() {
      let vm = this;
      vm.$store.dispatch("getUserDetails");
      document.body.classList.remove("lmodal-open");
      setTimeout(() => {
        vm.sideModals.import.show = false;
        vm.modalStatus = false;
      }, 300);
    },
    async downloadCsv(text){
      
      if(text == 'Earnings'){

        let response = await financialEarningService.get( "Monthly" );

        let earnings = response.earnings.reverse();
        let csv = '"Time Period","Earnings($)","Upwork Commission (incl.)($)","WHT (incl.)($)"\n';
        earnings.forEach((row) => {
          csv += '"'+row.x+'",';
          csv += '"'+row.y.earning+'",';
          csv += '"'+row.y.upwork_comminsion+'",';
          csv += '"'+row.y.WHT+'"';
          csv += "\n"; 
        });

        this.processCsvDownload(csv,'Financial Earnings Monthly.csv');
        
      }else if(text == 'Service Fee'){

        let response = await financialGraphService.getServiceFee( "Monthly", "All" );
        let service_fee = response.service_fee_table_data.reverse()
        let csv = '"Time Period","Service Fee($)"\n';

        service_fee.forEach((row) => {
          csv += '"'+row.x+'",';
          csv += '"'+Math.round(row.y.TotalFee)+'"';
          csv += "\n"; 
        });

        this.processCsvDownload(csv,'Financial Service Fee All ( Monthly ).csv');
      }else if(text == 'Withholding Tax'){

        let response = await financialWHTService.get( "Monthly", "All" );
        let wht = response.wht.reverse();
        let csv = '"Time Period","WHT($)"\n';

        wht.forEach((row) => {
          csv += '"'+row.x+'",';
          csv += '"'+Math.round(row.y)+'"';
          csv += "\n"; 
        });

        this.processCsvDownload(csv,'Withholding Tax All ( Monthly ).csv');
      }else if(text == 'Membership Fee'){

        let response = await financialMembershipFeeService.get( "Monthly" );
        let membership_fee = response.membership_fee.reverse();
        let csv = '"Time Period","Membership Fee($)"\n';

        membership_fee.forEach((row) => {
          csv += '"'+row.x+'",';
          csv += '"'+Math.round(row.y)+'"';
          csv += "\n"; 
        });

        this.processCsvDownload(csv,'Membership Fees Monthly.csv');
      }else if(text == 'Refunds'){

        let response = await financialRefoundService.get( "Monthly" );
        let refunds = response.refunds.reverse();
        let csv = '"Time Period","Refunded Amount($)"\n';

        refunds.forEach((row) => {
          csv += '"'+row.x+'",';
          csv += '"'+Math.round(row.y)+'"';
          csv += "\n"; 
        });

        this.processCsvDownload(csv,'Refunds Monthly.csv');
      }else if(text == 'Bonus'){

        let response = await financialBonusService.get( "Monthly" );
        let bonus = response.bonus.reverse();
        let csv = '"Time Period","Received Bonus Amount($)"\n';

        bonus.forEach((row) => {
          csv += '"'+row.x+'",';
          csv += '"'+Math.round(row.y)+'"';
          csv += "\n"; 
        });

        this.processCsvDownload(csv,'Bonus Monthly.csv');
      }else if(text == 'Connects'){

        let response = await financialConnectsService.get( "Monthly" );
        let connects = response.connects.reverse();
        let csv = '"Time Period","Total Connects Purchased","Fee($)"\n';

        connects.forEach((row) => {
          csv += '"'+row.x+'",';
          csv += '"'+row.y.totalConnects+'",';
          csv += '"'+Math.round(row.y.connectFee)+'"';
          csv += "\n"; 
        });

        this.processCsvDownload(csv,'Connects Monthly.csv');
      }else if(text == 'Cash Flow'){

        let response = await FinancialCashFlowService.get( "Monthly" );
        

        let inflow = response.inflow;
        let outflow = response.outflow;

        let csv = '"Inflow","Amount($)","","","","Outflow","Amount($)"\n';
        
        csv += '"Receivable Earning",';
        csv += '"'+Math.round(inflow.receivable_earning)+'",';
        csv += '"",',
        csv += '"",',
        csv += '"",',
        csv += '"Service Fee",';
        csv += '"'+Math.round(outflow.service_fee)+'",';
        csv += "\n"; 

        csv += '"Receivable Bounes",';
        csv += '"'+Math.round(inflow.receivable_bonus)+'",';
        csv += '"",',
        csv += '"",',
        csv += '"",',
        csv += '"WHT",';
        csv += '"'+Math.round(outflow.Wht)+'",';
        csv += "\n"; 

        csv += '"Total",';
        csv += '"'+Math.round(inflow.earning_total)+'",';
        csv += '"",',
        csv += '"",',
        csv += '"",',
        csv += '"Membership Fee",';
        csv += '"'+Math.round(outflow.membership_fee)+'",';
        csv += "\n"; 

        csv += '"",';
        csv += '"",';
        csv += '"",',
        csv += '"",',
        csv += '"",',
        csv += '"Connects Purchased",';
        csv += '"'+Math.round(outflow.connects)+'",';
        csv += "\n"; 

        csv += '"",';
        csv += '"",';
        csv += '"",',
        csv += '"",',
        csv += '"",',
        csv += '"Refunds",';
        csv += '"'+Math.round(outflow.refund)+'",';
        csv += "\n"; 

        csv += '"",';
        csv += '"",';
        csv += '"",',
        csv += '"",',
        csv += '"",',
        csv += '"Withdrawl Charges",';
        csv += '"'+Math.round(outflow.withdrawl_charges)+'",';
        csv += "\n"; 

        csv += '"",';
        csv += '"",';
        csv += '"",',
        csv += '"",',
        csv += '"",',
        csv += '"Total",';
        csv += '"'+Math.round(outflow.outflow_total)+'",';
        csv += "\n"; 

        this.processCsvDownload(csv,'Cash Flow.csv');
      }

    },
    processCsvDownload(csv,name){
      let vm = this;
      vm.$store.dispatch("getUserDetails").then(response => {
        if(response.is_active) {
          const anchor = document.createElement('a');
          anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
          anchor.target = '_blank';
          anchor.download = name;
          anchor.click();
        }
      });

      
    }
  },
  mounted() {     
    this.loading = false;
  },
};
</script>
